import React from 'react'

const Logo = ({ className, mobileMenuIsOpen }) => (
  <svg className={className} viewBox="0 0 124.97 35.05">
    <title>Leaft Foods</title>
    <path d="M32.1,34.57h4.81V1.22H32.1ZM50.52,13.92c3.8,0,6.16,2.69,6.54,7.35H43.88c.38-4.66,2.79-7.35,6.64-7.35m0,17.39c-4,0-6.5-3-6.69-8h18v-.67c0-7.83-4.18-12.44-11.3-12.44S39.16,14.79,39.16,22.62s4.19,12.43,11.36,12.43c6.3,0,10.49-3.31,11.3-8.93H57.11c-.63,3.27-3.08,5.19-6.59,5.19m25.57-.14C71.62,31.17,69,28,69,22.62s2.6-8.55,7.07-8.55,7.08,3.36,7.08,8.55S80.57,31.17,76.09,31.17ZM87.64,10.66H82.83v4.43s-1.18-4.91-8.35-4.91c-6.26,0-10.41,4.61-10.41,12.44s4.15,12.43,10.41,12.43c7,0,8.35-5.57,8.35-5.57v5.09h4.81Zm29.44,16.52v-13H125V10.66H112.79v-.1c2.89-1.05,4.62-3.26,4.62-6.05V1.22A4.81,4.81,0,0,0,112.6,6v4.64h-4v3.51h3.71V27.66c0,4.66,2.79,7.39,7.45,7.39a12.36,12.36,0,0,0,4.91-1V30.4a7.87,7.87,0,0,1-3.37.77c-2.64,0-4.18-1.45-4.18-4m-18.67-13h7.89V10.66H98.41V7.88c0-2.55,1.54-4,4.18-4a7.87,7.87,0,0,1,3.37.77V1a12.15,12.15,0,0,0-4.91-1C96.39,0,93.6,2.74,93.6,7.4v3.26H89.89v3.51H93.6v20.4h4.81ZM24.94,9.52V1.22H16.63a8.31,8.31,0,0,0,8.31,8.3M12.47,13.67V1.22H0A12.46,12.46,0,0,0,12.47,13.67M24.94,26.11V13.67H12.47A12.45,12.45,0,0,0,24.94,26.11" />
  </svg>
)

export default Logo
